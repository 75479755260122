require("jquery");
require("popper");
require("bootstrap");
require("@rails/activestorage").start();
require("plugins/graphql_chart");
require("plugins/copy_on_click");
require("docs");
require("ui-kit");
require("turbolinks").start()

window.RailsUJS = require("@rails/ujs");
RailsUJS.start();
